/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={<>ARCHITECTURAL REQUESTS</>}
                description="Per Article VI of the Restrictions for Shelby Estates Subdivision, any and all exterior improvements to your home or property must be approved by the Shelby Estates Architectural Control Committee. Once the Architectural Committee receives your formal request they have 30 days to review and make a decision as whether to approve or deny the request."
              />
              <RotatingCardBack
                image={bgBack}
                title="ACC Request Form"
                description="Please fill out the below Architectural Request Form. Once you complete the Architectural Request Form you will need to submit it to Shebly Estates HOA at shelby.estates.sub@gmail.com"
                action={{
                  type: "external",
                  route:
                    "https://firebasestorage.googleapis.com/v0/b/shelby-estates-hoa.appspot.com/o/docs%2FACC%20Request%20Form.pdf?alt=media&token=36806506-f88d-4030-b736-127d2379c79d",
                  label: "ACC Request Form.pdf",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="HOA Documentation"
                  description="Relevant records for Shelby Estates homeowners."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
